import React, {useState} from 'react';
import { Link, useParams } from "react-router-dom";
import styled from 'styled-components';
import {CDN} from "../../helpers";
import { Fade } from 'react-slideshow-image';

import {OzContainer, OzRow, OzCol, OzButton} from "@ozwol/webui"; //SPANISHGREY

import Meta from '../../components/Meta';


const Wrapper = styled.div`
`;
const Line = styled.div`
  width: 60px;
  height: 5px;
  background-color: #00141f;
`;
const Title = styled.div`
  font-size: 30px;
  line-height: 36px;
  font-weight: bold;
  color: #00141f;
  margin-top: 20px;
  margin-bottom: 20px;

  @media (max-width: 991px){
    font-size: 25px;
  }
`;
const Date = styled.div`
  margin-bottom: 20px;
  font-size: 16px;
`;
const Description = styled.div`

`;
const Image = styled.div`
  border: 1px solid #D0D0D0;
  border-radius: 17px;
  box-shadow: 0px 3px 3px #00000019;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  // width: ${props => props.width}px;
  max-width: 100%;

  & img{
    display: block;
    // width: ${props => props.width}px;
    max-width: 100%;
  }
`;

// const ImageCopyright = styled.div`
//   text-align: right;
//   margin-top: 3px;
//   font-size: 10px;
//   color: ${SPANISHGREY};

//   &>a {
//     color: inherit;
//   }
// `;


export default function Article() {
  const {lang} = useParams();
  const [example, setExample] = useState(1);

  return (
    <>
      <Meta
        title={(() => {switch(lang){
          case "it":
            return "Generative Background Expansion";
          default:
            return "Generative Background Expansion";
        }})()}
        description={(() => {switch(lang){
          case "it":
            return "Generative Background Expansion";
          default:
            return "Generative Background Expansion";
        }})()}
        canonicalSlug={(() => {switch(lang){
          case "it":
            return "blog/3-generativebackgroundexpansion";
          default:
            return "blog/3-generativebackgroundexpansion";
        }})()}
      />
      
      
      <Wrapper>
        <OzContainer size={"medium"}>
          <OzRow gap="25">
            <OzCol widthmd={12} order={2}>
              {example === 1 ? 
                <Image>
                  <Fade
                    duration={3000}
                    arrows={false}
                  >
                    <img
                      src={CDN+"website-assets/blog/3-generativebackgroundexpansion-1.jpg"}
                      alt={"Generative Background Expansion - Example 1"}
                    />
                    <img
                      src={CDN+"website-assets/blog/3-generativebackgroundexpansion-2.jpg"}
                      alt={"Generative Background Expansion - Example 1"}
                    />
                  </Fade>
                </Image>
              :
                <Image>
                  <Fade
                    duration={3000}
                    arrows={false}
                  >
                     <img
                      src={CDN+"website-assets/blog/3-generativebackgroundexpansion-3.jpg"}
                      alt={"Generative Background Expansion - Example 2"}
                    />
                    <img
                      src={CDN+"website-assets/blog/3-generativebackgroundexpansion-4.jpg"}
                      alt={"Generative Background Expansion - Example 2"}
                    />
                  </Fade>
                </Image>
              }
              <br/>
              <OzButton 
                variant="push-black"
                onClick={() => setExample(1)}
                pushed={example === 1}
              >
                {(() => {switch(lang){
                  default:
                    return "Example 1";
                }})()}
              </OzButton>
              &nbsp;&nbsp;&nbsp;
              <OzButton 
                variant="push-black"
                onClick={() => setExample(2)}
                pushed={example === 2}
              >
                {(() => {switch(lang){
                  default:
                    return "Example 2";
                }})()}
              </OzButton>
              <br/><br/>
              {/* <ImageCopyright>Photo: <a href="https://unsplash.com/it/@alirezadolati" target="_blank" rel="noreferrer">Alireza Dolati</a></ImageCopyright> */}
            </OzCol>
            <OzCol widthmd={12} order={1}>
              <Date>
                {(() => {switch(lang){
                  default:
                    return "Wednesday February 25, 2025";
                }})()}
              </Date>
              <Line/>
              <Title>
                {(() => {switch(lang){                 
                  default:
                    return "Generative Background Extension - Seamlessly Expand Your Image Background with AI";
                }})()}
              </Title>
              <Description>
                {(() => {switch(lang){                  
                  default:
                    return <>
                      <p>
                        Discover how Ozwol's advanced generative AI effortlessly extends image backgrounds while preserving natural lighting and shadow diffusion. Unlike conventional methods, Ozwol intelligently recreates missing areas, ensuring a seamless enlargement that stays true to the original photographic setup.
                      </p>
                      <p>
                        Our AI-driven feature enhances your images by:
                        <ul>
                          <li>Replicating the original photographic environment</li>
                          <li>Maintaining natural shadow diffusion and lighting consistency</li>
                          <li>Delivering high-quality, photo-realistic results—at scale</li>
                        </ul>
                      </p>
                      <h3>
                        How It Works:
                      </h3>
                      <p>
                        1. <b>Script configuration:</b><br/>
                        Ozwol sets up the AI process to generate a seamless background extension.
                      </p>  
                      <p>
                        2. <b>Image Upload:</b><br/>
                        Easily upload your images via API or Hotfolders—our automated system integrates effortlessly into your workflow.
                      </p>
                      <p>
                        3. <b>AI Processing:</b><br/>
                        Ozwol analyzes the images and generates a flawless extended background, preserving natural shades and lighting.
                      </p>
                      <p>
                        At the end of the process, you receive high-quality, ready-to-use outputs, tailored to your specifications.<br/>
                        Want to integrate Generative Background Extension into your workflow? Contact us today to explore how Ozwol can optimize your image production!
                      </p>
                    </>;
                }})()}
              </Description>
              <br/>
              <Link to={"/"+lang+"/contact-us"}>
                <OzButton variant="chetwodeblue">
                  {(() => {switch(lang){
                    // case "it":
                    //   return "ColorSwatch: Generate Image Samples for Color Swatches";
                    default:
                      return "Contact us";
                  }})()}
                </OzButton>
              </Link>
            </OzCol>
          </OzRow>
        </OzContainer>
      </Wrapper>    
      
    </>
  )

}

